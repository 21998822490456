<template>
    <div>
        <b-card class="bg-blur">
            <video
              class="token__video w-100"
              src="@/assets/video/Plan1.mp4"
              autoplay
              loop
              muted
            ></video>

            <div class="plan-name">
                {{ membership.name }}
            </div>
            <div class="plan-price mt-2 mb-2">
                <span>${{ coinFormat(membership.price) }}</span>/usd
            </div>
            <h4 class="mt-3">
                Benefits
            </h4>
            <div class="mt-1 mb-1 row" v-for="benefit in JSON.parse(membership.benefits)" :key="benefit">
                <div class="col-6">
                    {{ splitBread(benefit) }}
                </div>
                <div class="col-6 text-right">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 1.875L7 12.875L2 7.875" stroke="url(#paint0_linear_1260_7303)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                        <linearGradient id="paint0_linear_1260_7303" x1="10" y1="1.875" x2="10" y2="12.875" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFD252"/>
                        <stop offset="1" stop-color="#F07F4F"/>
                        </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
export default {
    props: ['membership'],
    data () {
        return {
            modalPurchase: false
        }
    },
    methods: {
        splitBread (name) {
            return name.replace(/-/g, ' ');
        }
    }
}
</script>
<style scoped>
    .plan-name{
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
    }
    .plan-price{
        font-weight: 300;
        font-size: 36px;
        line-height: 40px;
    }
    .plan-price span {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
    }
</style>