<template>
    <div class="text-left">
        <h2 class="text-white">
            Signals
        </h2>
        <b-card>
            <div class="row">
                <div class="col-12 col-md-2">
                    <b-avatar src="https://placekitten.com/300/300" size="5rem" ></b-avatar>
                </div>
                <div class="col-12 col-md-7 pt-2">
                    <h4>
                        {{ user.username }}
                    </h4>
                    <h6>
                        {{ user.email }}
                    </h6>
                </div>
                <div class="col-12 col-md-3 pt-2">
                    <h2>
                        ${{ user.signal_price }}
                    </h2>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
export default {
    props: ['user']
}
</script>