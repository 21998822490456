<template>
    <div>
        <div>
            <div class="row">
                <div class="col-12 col-sm-5">
                    <Membership :membership="cart.details" v-if="cart.model == 'membership'"/>
                    <Course :course="cart.details" v-if="cart.model == 'academy'"/>
                    <Tokens :token="cart.details" v-if="cart.model == 'token'"/>
                    <Signal :user="cart.details" v-if="cart.model == 'signal'"/>
                </div>
                <div class="col-12 col-sm-7 text-left">
                    <b-card class="bg-full">
                        <div class="row mt-4">
                            <div class="col-12 col-sm-5 text-center">
                                <qrcode-vue :value="cart.wallet" size="200" level="M"/>
                            </div>
                            <div class="col-12 col-sm-7 pt-3">
                                <h4>
                                    Wallet Address
                                </h4>
                                <Copy :address="cart.wallet" class="mt-3 mb-3" />
                                <h5 class="mt-3">
                                    Quantity
                                </h5>
                                <h3>
                                    {{ coinFormat(cart.price) }} {{ cart.payment_method }}
                                </h3>
                                <p class="mt-2">
                                    Please send the corresponding amount to this wallet and click Verify Payment (You will receive confirmation in up to 5 minutes)
                                </p>
                                <div class="" v-if="message != null && message != 'success'">
                                    Received {{ coinFormat(message) }} from {{coinFormat(cart.price)}} {{ cart.payment_method }}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4" v-if="cart.status == 0 || cart.status == 1">
                            <div class="col-6">
                                <b-button variant="link" size="lg" class="w-100" @click="cancelPayment">
                                    Cancel
                                </b-button>
                            </div>
                            <div class="col-6">
                                <ButtonAction size="lg" :loading="loading" loadingTx="Verifying payment" block="true" title="Verify payment" @click="confirmPayment"/>
                            </div>
                        </div>
                        <div v-else>
                            <b-button variant="primary" class="w-100" disabled>
                                <span v-if="cart.status != 2">
                                    Payment made
                                </span>
                                <span v-else>
                                    Payment canceled
                                </span>
                            </b-button>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
import Membership from './Membership.vue'
import Course from './Course.vue'
import Tokens from './Tokens.vue'
import Signal from './Signal.vue'

import { mapActions, mapState } from 'vuex'
export default {
    props: ['id'],
    components: {
        QrcodeVue,
        Membership,
        Course,
        Tokens,
        Signal
    },
    data () {
        return {
            loading: false,
            message: null,
            polling: null
        }
    },
    beforeUnmount () {
        console.log("UNMOUNT")
        clearInterval(this.polling)
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('auth', ['getUserInfo']),
        ...mapActions('cart', ['getCart', 'checkPayment', 'destroyCart']),
        getData () {
            this.loading = true
            this.getCart(this.id).then(() => {
                this.loading = false
               /*  if(this.cart.model != 'Credits' && this.cart.status != 2 && this.cart.status != 4 && this.cart.status != 5 && this.cart.status != 6) {
                    this.autoUpdate()
                } else {
                    console.log("STOPING POLLING")
                    clearInterval(this.polling)
                } */
            })
        },
        autoUpdate() {
            this.polling = setInterval(() => {
                this.confirmPayment()
            }, 3000)
        },
        cancelPayment () {
            this.loading = true
            this.destroyCart(this.id).then(() => {
                openNotification('Purchase canceled')
                this.loading = false
                this.$router.push({ name: 'Transactions' })
            })
        },
        confirmPayment () {
            this.loading = true
            this.checkPayment(this.id).then(response => {
                this.message = response
                this.loading = false
                if(response == 'success') {
                    this.getUserInfo()
                    openNotification()
                    this.getData()
                    this.polling = null
                    this.$router.push({ name: 'Transactions' })
                }
            })
        }
    },
    computed: {
        ...mapState('cart', ['cart'])
    }
}
</script>