<template>
    <div>
        <b-card class="bg-full text-center">
            <img src="@/assets/images/tokens/bta.png" alt="">
            <p class="mt-4">
                Por favor envia la cantidad que deseas comprar, recuerda que
                <h2>
                    1 USD = 7 BTA
                </h2>
            </p>
        </b-card>
    </div>
</template>
<script>
export default {
    props: ['token']
}
</script>